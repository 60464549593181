// extracted by mini-css-extract-plugin
export var lbContainerOuter = "x_tC";
export var lbContainerInner = "x_tD";
export var movingForwards = "x_tF";
export var movingForwardsOther = "x_tG";
export var movingBackwards = "x_tH";
export var movingBackwardsOther = "x_tJ";
export var lbImage = "x_tK";
export var lbOtherImage = "x_tL";
export var prevButton = "x_tM";
export var nextButton = "x_tN";
export var closing = "x_tP";
export var appear = "x_tQ";