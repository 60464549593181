// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "m_qh d_gv d_cs";
export var heroHeaderCenter = "m_gw d_gw d_cs d_dw";
export var heroHeaderRight = "m_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "m_qj d_gr d_cw";
export var heroParagraphCenter = "m_gs d_gs d_cw d_dw";
export var heroParagraphRight = "m_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "m_qk d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "m_ql d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "m_qm d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "m_qn d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "m_qp d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "m_qq q_qq";
export var heroExceptionNormal = "m_qr q_qr";
export var heroExceptionLarge = "m_qs q_qs";
export var Title1Small = "m_qt q_qt q_qV q_qW";
export var Title1Normal = "m_qv q_qv q_qV q_qX";
export var Title1Large = "m_qw q_qw q_qV q_qY";
export var BodySmall = "m_qx q_qx q_qV q_rc";
export var BodyNormal = "m_qy q_qy q_qV q_rd";
export var BodyLarge = "m_qz q_qz q_qV q_rf";