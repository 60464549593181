// extracted by mini-css-extract-plugin
export var tileContent = "n_qB d_w d_H d_Z";
export var teamTextLeft = "n_qC d_dv";
export var teamTextCenter = "n_qD d_dw";
export var teamTextRight = "n_qF d_dx";
export var alignLeft = "n_qG d_w d_bz d_fp d_bG d_dv";
export var alignCenter = "n_bP d_w d_bz d_fq d_bD d_dw";
export var alignRight = "n_qH d_w d_bz d_fr d_bH d_dx";
export var teamContainer = "n_qJ d_dW";
export var teamContainerFull = "n_qK d_dT";
export var teamRowWrapper = "n_qL d_cc";
export var teamTileWrapper = "n_j3 d_j3 d_Z d_cv";
export var teamTileSquareWrapper = "n_qM d_j4 d_Z d_cv";
export var teamTileRoundWrapper = "n_j4 d_j4 d_Z d_cv";
export var teamTileNoGuttersWrapper = "n_j5 d_j5 d_Z";
export var teamHoverNoGutters = "n_j6 d_j6 d_0 d_w d_H d_bz d_bD d_bP d_bm";
export var teamImageText = "n_kc d_kc d_bl d_w d_by";
export var teamInfoWrapperSquare = "n_qN d_j7";
export var teamInfoWrapperRound = "n_j7 d_j7";
export var teamInfoWrapper = "n_qP d_j8 d_0";
export var teamInfoWrapperNoGutters = "n_j9 d_j9 d_w d_H d_bD d_bM d_bJ d_c7";
export var teamImgWrapper = "n_jZ d_jZ";
export var teamImgWrapperAlt = "n_j0 d_j0";
export var teamImgWrapperNoGutters = "n_kb d_kb";
export var teamHeader = "n_qQ d_cw";
export var teamHeaderAlt = "n_qR d_cw";
export var teamHeaderNoGutters = "n_qS d_cw d_cD";